import React, { useState, useEffect } from "react"
import style from "./winery-list.mod.scss"
import WineryCard from "./winery-card"
import Navigator from "../global/navigator"
import Button from "../button/button"
import Container from "../container/container"
import Select from "../select/select"
import Input from "../form/Input"

const WineryList = ({ items, title, hasFilter, pagesize = 12 }) => {
  const allRegions = [...new Set(items.map((item) => item.region_name))].sort()

  const [filteredList, setFilteredList] = useState(items)
  const [list, setList] = useState([...items.slice(0, pagesize)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(items.length > pagesize)
  const [selectedRegion, setSelectedRegion] = useState("")
  const [searchValue, setSearchValue] = useState("")

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < filteredList.length
      const nextResults = isMore
        ? filteredList.slice(currentLength, currentLength + pagesize)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
      setHasMore(currentLength + nextResults.length < filteredList.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore])

  useEffect(() => {
    const newFilteredList = filterItems()
    setFilteredList(newFilteredList)
    setList([...newFilteredList.slice(0, pagesize)])
    setHasMore(pagesize < newFilteredList.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion])
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const newFilteredList = filterItems()
      setFilteredList(newFilteredList)
      setList([...newFilteredList.slice(0, pagesize)])
      setHasMore(pagesize < newFilteredList.length)
    }, 200)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])
  
  const filterItems = () => {
    return items.filter((item) => {
      let r = true
      if (selectedRegion) {
        r = item.region_name === selectedRegion
      }
      if (r && searchValue && searchValue.length > 2) {
        r = false
        let s = searchValue.toLowerCase()
        if (item.name.toLowerCase().indexOf(s) > -1) { r = true }
        if (!r && item.region_name.toLowerCase().indexOf(s) > -1) { r = true }
        if (!r && item.country.toLowerCase().indexOf(s) > -1) { r = true }
      }
      return r
    })
  }

  return (
    <div className={style.list}>
      {title && <h3>{title}</h3>}
      {hasFilter && (
        <Container pt={1} pb={1}>
          <div className={style.filters}>
            <Select
              options={[{ value: "", label: "All Regions" }, ...allRegions]}
              onChange={(e) => {
                setSelectedRegion(e.target.value)
              }}
              value={selectedRegion}
            />
            <Input
              name="searchValue"
              value={searchValue}
              placeholder="Search Wineries, Regions & Countries"
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              small
            />
          </div>
        </Container>
      )}
      <div className={style.list__grid}>
        {list.map((item) => (
          <Navigator recordId={item.id}>
            <WineryCard {...item} />
          </Navigator>
        ))}
      </div>
      {(!list || list.length === 0) &&
        <Container pt={1} pb={1} textAlign="center">no wineries found</Container>
      }
      {hasMore && (
        <Container width="large" gutters pt={1} pb={1} textAlign="center">
          <Button onClick={handleLoadMore}>Load more</Button>
        </Container>
      )}
    </div>
  )
}

export default WineryList

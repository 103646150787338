import React from "react"
import style from "./winery-card.mod.scss"
import demoImage from "../../assets/images/stock-winery.jpeg"
import Pin from "../icons/pin"

const WineryCard = ({
  name,
  banner,
  description,
  country,
  region_id,
  region_name,
}) => {
  const handleImgError = (e) => {
    e.target.src = demoImage
  }

  return (
    <div className={style.card}>
      <img src={banner} alt="" onError={handleImgError} />
      <div className={style.card__content}>
        <h5 className={style.card__country}>{country}</h5>
        <h4>{name}</h4>
        <p className={style.card__description}>{description}</p>
        <div className={style.card__address}>
          <Pin />
          <p>{`${region_name}, ${country}`}</p>
        </div>
      </div>
    </div>
  )
}

export default WineryCard

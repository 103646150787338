import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import WineryList from "../components/winery/winery-list"
import Container from "../components/container/container"

const Wineries = ({
  data: {
    wineries,
    datoCmsWineriesPage: { id, title, description, seoMetaTags },
  },
}) => {
  const breadcrumbs = [{ title: title, id: id }]

  return (
    <Layout seo={seoMetaTags}>
      <Intro
        title={title}
        description={description}
        breadcrumbs={breadcrumbs}
      />
      <Container pb={2} gutters width={"large"}>
        <WineryList pageSize={16} items={wineries.nodes} hasFilter />
      </Container>
    </Layout>
  )
}

export default Wineries

export const query = graphql`
  {
    datoCmsWineriesPage {
      id: originalId
      title
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    wineries: allPortalWinery {
      nodes {
        banner
        address
        name
        id
        wineryId
        state
        country
        description
        region_id
        region_name
      }
    }
  }
`

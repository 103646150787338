import React, { useState } from "react"
import cn from "classnames"
import style from "./select.mod.scss"

const Select = ({name, value, options, disabled, onChange, className}) => {
  
  const [isFocused, setIsFocused] = useState(false)
  
  return (
    <div className={cn(style.select, className, {[style.isFocused]: isFocused})}>
      <select onChange={onChange} value={value} name={name} disabled={disabled} onFocus={() => { setIsFocused(true) }} onBlur={() => { setIsFocused(false) }}>
        {options.map((opt,i) => {
          let isString = typeof opt === "string"
          return (
            <option key={`opt${i}`} value={isString ? opt : opt.value}>{isString ? opt : opt.label}</option>
          )
        })}
      </select>
    </div>
  )
}

export default Select
